@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.top_rated_nfts {
  background: #F6F5F7;
  padding-top: 26px;
  padding-bottom: 74px;
}

.slider_btns {
  display: flex;
  flex: 0.05;
  justify-content: flex-end;
  line-height: 46px;

  span {
    height: auto;
    svg {
      margin: 0 20px;

      &:last-child {
        margin: 0 0 0 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.spotlight_separate {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  flex: 0.05 1;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
}

.spotlight_section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.spotlight_section_loader {
  display: block;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.spotlight_section_loader {
  display: block;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.col_card__clr {
  padding: 0 8px !important;
}