@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.rst__clr {
    label {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: $primary_font_color;
        margin: 0 0 6px 0;

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: $primary_font_color;
            margin: 0 0 6px 0;
        }

        @media (max-width: 768px) {
            font-size: 18px;
        }
        & + .switch__clr {
            display: flex;
            justify-content: space-between;
        }
    }

    input,
    textarea,
    select {
        width: 100%;
        margin: 0 6px 6px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #666666;
        padding: 16px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 2px;
        appearance: inherit;
    }
    select {
        // padding: 6px 9px;
        height: 58px;
    }
}

.fieldset {
    margin: 8px 0;
}

.group {
    margin: 32px 0 0;
}

.label {
    display: flex;
    justify-content: space-between;
    .section_one {
        display: flex;
        .label_info {
            margin: 0 5px;
            font-size: 18px;
            font-weight: normal;
            align-content: center;
            justify-content: center;
            align-items: center;
            color: #666666;
        }
    }
}
